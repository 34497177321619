/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if a preview query param exists on the url, and if so load the appropriate template.
 */

import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import use404Data from "@staticQueries/Error404Query.js";

const Preview = loadable(() => import("@pageTemplates/Preview"));
const DevError = loadable(() => import("@templates/DevError"));
const Error404 = loadable(() => import("@templates/Error404"));
const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);

const NotFoundPage = ({ location }) => {
  const { heading, descriptor, button, secondaryButton } = use404Data();
  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(false);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isPreview = !!(
      urlParams.get("x-craft-live-preview") || urlParams.get("x-craft-preview")
    );

    if (isPreview) {
      setPreview(true);
    } else {
      setLoaded(true);
    }
  }, []);

  if (preview) {
    return <Preview location={location} />;
  }

  if (process.env.GATSBY_IN_DEV) {
    return (
      <DefaultPageContainer flex>
        <DevError />
      </DefaultPageContainer>
    );
  }

  return (
    <DefaultPageContainer>
      <Error404
        heading={heading}
        descriptor={descriptor}
        secondaryButton={secondaryButton}
        button={button}
      />
    </DefaultPageContainer>
  );
};

export default NotFoundPage;
